.Spells {
  font-size: var(--font-xl);
  height: var(--font-xl);
  line-height: 1;
  padding: 1rem 0;
}

@media only screen and (orientation: landscape) and (max-width: 800px) {
  .Spells {
    padding: 0;
  }
}

.NewScore {
  color: var(--green);
}

.Score {
  color: var(--black);
}

.PreviouslySpelled {
  color: var(--gray);
}

.Missed {
  color: var(--red);
}
