.LearnerApp {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: var(--gray);
  color: var(--white);
  padding: 0.5rem;
}

.ToGo {
  font-size: var(--font-md);
}
