.LeftToSpell {
  color: var(--blue);
}

.HeaderPrefix {
  color: var(--blue);
}

.SpelledButton {
  font-size: var(--font-sm);
  color: var(--blue);
}
