.Letter {
  border-radius: 8px;
  background-color: var(--yellow);
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 3px #333333;
  opacity: 0.9;
  transition: ease;
  text-shadow: 0 0 1px #333333;
  cursor: pointer;
  transition: opacity 500ms;
  font-family: var(--serif);
}

.Letter.Selected {
  border: 2px solid var(--cyan);
}

.Letter.Targetable {
  border: 2px solid var(--red);
}

.Letter:hover {
  opacity: 1;
}
