.Spelled,
.Spelled > a {
  color: var(--green);
}

.Unspelled,
.Unspelled > a {
  color: var(--red);
}

.Missed {
  color: var(--gray);
}

.Good {
  color: var(--green);
}

.Okay {
  color: var(--blue);
}

.Bad {
  color: var(--yellow);
}

.Awful {
  color: var(--red);
}
