.Button {
  font-size: var(--font-md);
  background-color: var(--blue);
  border: none;
  box-shadow: 0px 1px 3px 0px var(--black);
  border-radius: 4px;
  color: white;
  font-family: var(--sans-serif);
}

.Button.Secondary {
  background-color: var(--gray);
}

.Button.Accent {
  background-color: var(--white);
  color: var(--blue);
}
.Button:disabled {
  opacity: 0.5;
}

.Button.Small {
  font-size: var(--font-sm);
}
