.Hint {
  font-size: var(--font-sm);
}

.Hints {
  padding-bottom: 1rem;
  font-weight: bolder;
}

.SpelledList {
  padding-bottom: 1rem;
}
