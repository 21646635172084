.Spelled {
  color: var(--green);
}

.Unspelled {
  color: var(--red);
}

.Words:not(:last-child) {
  padding-bottom: 1rem;
}

.Info {
  font-size: var(--font-sm);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Good {
  color: var(--green);
}

.Okay {
  color: var(--blue);
}

.Bad {
  color: var(--yellow);
}

.Awful {
  color: var(--red);
}
