.Actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 1;
  min-height: calc(var(--font-md) + 0.5rem);
}

.Right {
}

.Middle {
}

.Left {
}
