.Modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0.5rem;
  padding: 1rem;
  background-color: var(--white);
  z-index: 200;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px var(--black);
  display: flex;
  flex-direction: column;
}

.Title {
  font-weight: 900;
  font-size: var(--font-lg);
  padding-bottom: 0.25rem;
}

.Content {
  font-size: var(--font-md);
  position: relative;
  flex-grow: 1;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
