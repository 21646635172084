.Slot {
  box-shadow: inset 0 0 3px var(--black);
  border-radius: 2px;
}

.Slot:not(.Selected):not(.Targetable) {
  margin: 2px;
}

.Slot.Selected {
  border: 2px solid var(--cyan);
}

.Slot.Targetable {
  border: 2px solid var(--red);
}

.Slot.Occupied {
  box-shadow: none;
}
